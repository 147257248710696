<template>
    <div>
      <v-snackbar
        :timeout="2000"
        absolute
        top
        color="blue"
        right
        text
        v-model="showSnackbar"
      >
        {{ msg }}
      </v-snackbar>
      <v-layout style="background-color:#9B8DFD; height:100vh;" justify-center align-center wrap>
        <v-flex xs12>
        <v-img height="100vh" src="../assets/images/Asset1.png" class="align-center">
        <v-layout wrap justify-center>
          <v-flex xs6 align-self-center text-center>
            <v-form ref="file">
              <v-card color="transparent" elevation="0">
                <span style="color:white">Welcome to</span> <br />
                <span style="font-size:25px;color:white">Chavara Educational and Charitable Trust Pala</span><br>
      <span style="font-size:25px;color:white">"TRUST INFORMATION SYSTEM"</span>
                <v-img src="@/assets/images/login-main.svg" height="90" contain />
                <v-layout wrap justify-center>
                    <v-flex xs12 sm8 lg4 py-3>
                    <v-text-field
                        name="phone"
                        label="Mobile"
                        v-model="phone"
                        hide-details="auto"
                        dark
                        dense
                        :rules="phoneRules"
                    ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap justify-center pb-4>
                    <v-flex xs12 sm8 lg4 py-1>
                    <v-text-field
                        name="password"
                        label="Password"
                        v-model="password"
                        hide-details="auto"
                        class="text-white"
                        dark
                        dense
                        type="password"
                        :rules="[v => !!v || 'password is required']"
                        @keyup.enter="login()"
                    ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-btn
                depressed
                :ripple="false"
                dark
                @click="login()"
                color="#766BC0"
                >Continue</v-btn>
              </v-card>
              </v-form>
          </v-flex>
        </v-layout>
        </v-img>
        </v-flex>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
import store from './../store'
export default {
  data() {
    return {
      phone: null,
      password: null,
      timeout: 5000,
      msg: null,
      showSnackbar: false,
     

      valid: true,
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) =>
          (v && v.length <= 10) || "invalid number",
        (v) =>
          (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  methods: {
    login() {

      if(this.$refs.file.validate())
      {

      
      var loginData = {};
      loginData["phone"] = this.phone;
      loginData["password"] = this.password;
      axios({
        method: "post",
        url: "/admin/login",

        data: loginData,
      })
        .then((response) => {
          if (response.data.status == true) {
            localStorage.setItem("token", response.data.token);
            store.commit("userType",response.data.role);
            store.commit("loginUser",response.data.token);
            store.commit("userData",response.data.user);
            store.commit("userHostel",response.data.hostelName);
            if(response.data.role == 'HostelWarden')
            {
              this.getMe();
            }
            // if(response.data.role=="SuperAdmin")
            // this.$router.push("/admin")
            // else
            // this.$router.push("/laundaryDashboard")
          } else {
            this.msg = response.data.msg;
            this.showSnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    },
     getMe() {
      axios({
        method: "post",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) { 
             store.commit("userData",response.data.data);
             store.commit("userType",response.data.role);
             store.commit("userHostel",response.data.hostelname);
            //  if(response.data.blockId){
             store.commit("blockId",response.data.blockId);
            //  }

            // store.commit("loginUser",response.data.token);
            // store.commit("userData",response.data.user);
          }
        })
        .catch((err) => {
          console.log(err());
        });
      
            
    }
  },
}; 
</script>
<style>
.text-white input {
      color: white !important;
    } 
</style>